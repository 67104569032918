
import "./components/scss/app.scss"

export default {
    name: 'App',
    props: {
    },
    mounted: function () {
      
    }
}
