
import { mapState } from 'vuex'

export default {
    name: 'SideNav',
    computed: mapState([
        'sessionData'
    ]),
    methods: {
        initSideNav: function () {
            var showNavbar = (toggleId, navId, bodyId, headerId) => {
                const toggle = document.getElementById(toggleId),
                    nav = document.getElementById(navId),
                    bodypd = document.getElementById(bodyId),
                    headerpd = document.getElementById(headerId)

                // Validate that all variables exist
                if (toggle && nav && bodypd && headerpd) {
                    toggle.addEventListener('click', () => {
                        // show navbar
                        nav.classList.toggle('show')
                        // change icon
                        toggle.classList.toggle('bx-x')
                        // add padding to body
                        bodypd.classList.toggle('dashboard-pd')
                        // add padding to header
                        headerpd.classList.toggle('body-pd')
                    })
                }
            }
            showNavbar('header-toggle', 'nav-bar', 'body-pd', 'header')
            const linkColor = document.querySelectorAll('.nav_link')
            function colorLink() {
                if (linkColor) {
                    linkColor.forEach(l => l.classList.remove('active'))
                    this.classList.add('active')
                }
            }
            linkColor.forEach(l => l.addEventListener('click', colorLink));
        },
        setView: function () {
            var path = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
            // console.log(this.$router.options.routes);
            // pathlist = this.$root.$router.getRoutes();
            var linkIdArray = [];
            window.$('#route_list .nav_link').each(function () {
                linkIdArray.push(this.id);
            });
            linkIdArray.forEach(function (route) {
                if (path == route.replace('-link', '')) {
                    window.$('#' + route).addClass('active');
                }
            });

        }
    },
    mounted() {
        this.initSideNav();
        this.setView();
    }
}
