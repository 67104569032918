
import { Toast } from 'bootstrap'

export default {
    data() {
        return {
            titleMessage: null
        }
    },
    methods: {
        show: function (type, message) {
            switch (type) {
                case 'danger':
                    this.titleMessage = 'Error! ';
                break;
                case'warning':
                    this.titleMessage = 'Warning!, ';
                break;
                case 'primary':
                    this.titleMessage = '';
                break;
                case 'info':
                    this.titleMessage = 'Info, ';
                break;
                case 'success':
                    this.titleMessage = 'Success, ';
                break;
            }
            window.$('#title').text(this.titleMessage);
            window.$('#message').text(message);
            window.$('#myToastEl').addClass('bg-'+type);
            var newToast = new Toast(document.getElementById('myToastEl'));
            newToast.show();
        }
    },
    mounted() {
        
    }
}
