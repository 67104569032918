

//const BASE_URL = 'http://127.0.0.1/tapas-rest';
const BASE_URL = 'http://maksdtstpas.kinsta.cloud/tapas-rest';
//const ROUTE_URL = '';
//const ROUTE_URL = '/tapas-resources/dist';
const CONSUMER_KEY = 'ck_99b5a07a9b4f96d13f61ce018e098f9a0c6c5c9c';
const CONSUMER_SECRET = 'cs_96619d6337c21d853741f24882e0dd0f6287335f';


export default {
    BASE_URL: BASE_URL,
    //ROUTE_URL: ROUTE_URL,
    CONSUMER_KEY: CONSUMER_KEY,
    CONSUMER_SECRET: CONSUMER_SECRET
}