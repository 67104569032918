
import store from '../store'
import { mapState } from 'vuex'

export default {
    name: 'Header',
    computed: mapState([
        'sessionData'
    ]),
    data() {
      return {
        avatarUrl: null
      }
    },
    methods: {
        logOutUser: function () {
            store.commit('RESET_SESSION_DATA');
            store.commit('RESET_LAST_SELECTED_VIEW');
            if (!this.sessionData) {
                this.$router.push('/login');
            }
        }
    },
    mounted() {
      this.avatarUrl = this.sessionData.user_avatar;
    },
}
